import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/nedix/projects/internal/nedix.io/git_modules/gatsby-theme-carbon/packages/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Overview</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Resources</AnchorLink>
  <AnchorLink mdxType="AnchorLink">References</AnchorLink>
    </AnchorLinks>
    <h2>{`Resources`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-yaml",
        "metastring": "path=/applicationset.yaml",
        "path": "/applicationset.yaml"
      }}>{`---
apiVersion: argoproj.io/v1alpha1
kind: ApplicationSet

metadata:
  name: <your-project>
  namespace: argocd

spec:
  generators:
    - matrix:
        generators:
          - merge:
              mergeKeys:
                - name
              generators:
                - clusters: {}
                - list:
                    elements:
                      - name: in-cluster
                        overlay: local
                      - name: production
                        overlay: prod
          - git:
              repoURL: https://gitlab.com/<your-project>.git
              revision: main
              directories:
                - path: core/base/*
  template:
    metadata:
      name: '{{path.basename}}'
    spec:
      project: <your-project>
      source:
        repoURL: https://gitlab.com/<your-project>.git
        targetRevision: main
        path: 'core/overlays/{{overlay}}/{{path.basename}}'
      destination:
        server: '{{server}}'
      syncPolicy:
        automated:
          prune: false
          selfHeal: true
        retry:
          limit: 15
          backoff:
            duration: 15s
            factor: 2
            maxDuration: 5m
        syncOptions:
          - CreateNamespace=true
          - ApplyOutOfSyncOnly=true
          - PruneLast=true
`}</code></pre>
    <h2>{`References`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://community.hetzner.com/tutorials/install-kubernetes-cluster"
        }}>{`Install a Kubernetes cluster on cloud servers (Hetzner)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developers.redhat.com/articles/2022/09/07/how-set-your-gitops-directory-structure"
        }}>{`How to set up your GitOps directory structure (RedHat)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://cloud.google.com/anthos-config-management/docs/how-to/use-repo-kustomize-helm#example_repository_architecture_for_kustomize_configurations"
        }}>{`Example repository architecture for Kustomize configurations (Google)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://fabianlee.org/2022/04/18/kubernetes-kustomize-with-helm-charts/"
        }}>{`Kustomize with Helm charts (Fabian Lee)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://cloud.google.com/anthos-config-management/docs/how-to/use-repo-kustomize-helm"
        }}>{`Use a repo with Kustomize configurations and Helm charts (Google)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://grafana.com/blog/2019/09/09/how-grafana-labs-is-running-jaeger-at-scale-with-prometheus-and-envoy/"
        }}>{`How Grafana Labs Is Running Jaeger at Scale with Prometheus and Envoy`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.cncf.io/blog/2020/12/22/argocd-kubevela-gitops-with-developer-centric-experience/"
        }}>{`ArgoCD + KubeVela: GitOps with Developer-centric Experience (CNCF)`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      